<script>
import Table from "@/Table.vue";

export default {
    components: {
        Table
    },
    computed: {
        authLoaded() {
            return !this.$auth.loading;
        }
    },
    watch: {
        authLoaded: function(newValue) {
            if (newValue && !this.$auth.isAuthenticated) {
                this.$auth.loginWithRedirect();
            }
        }
    }
};
</script>

<template>
    <div id="app">
        <b-navbar type="is-primary">
            <template #brand>
                <b-navbar-item tag="router-link" to="/">
                    Versions
                </b-navbar-item>
            </template>
        </b-navbar>

        <Table v-if="!$auth.isLoading && $auth.isAuthenticated" />
    </div>
</template>

<style></style>
