import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import { Auth0Plugin } from "@/auth.js";
import "@/theme.scss";
import api from "@/api.js";

Vue.config.productionTip = false;
Vue.use(Buefy);

api.get("/config").then(response => {
    const { AUTH0_DOMAIN, AUTH0_CLIENT_ID } = response.data;

    Vue.use(Auth0Plugin, {
        domain: AUTH0_DOMAIN,
        clientId: AUTH0_CLIENT_ID,
        audience: "https://versions.belcy.com/",
    });

    new Vue({
        render: h => h(App)
    }).$mount("#app");
});
