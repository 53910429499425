<script>
import api from "@/api.js";
import axios from "axios";

export default {
    components: {},
    data() {
        return {
            isResizeLoading: false,
            dataLoading: true,
            deployments: {},
            clusters: [],
            filter: ""
        };
    },
    methods: {
        async loadData() {
            const token = await this.$auth.getTokenSilently();
            axios
                .all([
                    api.get("/v1/deployments", {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }),
                    api.get("/v1/clusters", {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                ])
                .then(
                    axios.spread((deploymentsResponse, clustersResponse) => {
                        this.deployments = deploymentsResponse.data;
                        this.clusters = clustersResponse.data;
                        this.dataLoading = false;
                    })
                );
        },
        getVersion(deployment, cluster) {
            if (Object.prototype.hasOwnProperty.call(deployment, cluster)) {
                return deployment[cluster].version;
            } else {
                return "";
            }
        }
    },
    computed: {
        tableColumns() {
            let tableColumns = [
                {
                    field: "name",
                    label: "Name",
                    searchable: true,
                    sortable: true
                }
            ];
            for (const cluster of this.clusters) {
                tableColumns.push({
                    field: cluster,
                    label: cluster[0].toUpperCase() + cluster.substring(1)
                });
            }
            return tableColumns;
        },
        tableData() {
            let tableRows = [];
            for (const deployment of this.deployments) {
                let row = {
                    name: deployment.name
                };
                for (const cluster of this.clusters) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            deployment.clusters,
                            cluster
                        )
                    ) {
                        row[cluster] = deployment.clusters[cluster].version;
                    } else {
                        row[cluster] = "";
                    }
                }
                tableRows.push(row);
            }
            return tableRows;
        }
    },
    created() {
        this.loadData();
    }
};
</script>

<template>
    <div class="container">
        <b-table
            v-if="!dataLoading"
            :data="tableData"
            :columns="tableColumns"
            default-sort="name"
            focusable
        ></b-table>
        <b-loading
            :is-full-page="true"
            v-model="dataLoading"
            :can-cancel="true"
        ></b-loading>
    </div>
</template>

<style></style>
