import axios from "axios";
import qs from "qs";

const envBaseURL = process.env.VUE_APP_API_URL; // WebpackDefine will inline the value if present. Can't use a ternary here cause this is not a real code evaluation!
const rootURL = envBaseURL || `${window.location.origin}/api`;

const api = axios.create({
    withCredentials: true,
    baseURL: `${rootURL}`,
    headers: { "X-Requested-With": "XMLHttpRequest" },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
});

export default api;
